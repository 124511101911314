import React, { useContext, useEffect } from 'react';
import { useQuery }                     from 'react-query';
import { SimpleGrid, Box }              from '@chakra-ui/react';

import Spinner, { SpinnerContainer } from '@interness/web-core/src/components/modules/Spinner/Spinner';
import Wrapper                       from '@interness/web-core/src/components/structure/Wrapper/Wrapper';
import Spacer                        from '@interness/web-core/src/components/structure/Spacer/Spacer';

import GridItem        from '../GridItem/GridItem';
import { EApiContext } from '../../context/EApi';
import Error           from '../Error/Error';

const RandomShopItems = ({ limit }) => {
  const { fetchRandomProducts, settings } = useContext(EApiContext);

  const { isLoading, isError, data, error } = useQuery(['products', {
    limit: limit,
  }], fetchRandomProducts, {
    refetchOnWindowFocus: false,
    cacheTime: 'Infinity',
    staleTime: 'Infinity',
  });

  useEffect(() => {
    let mounted = true
    return () => {
      mounted = false;
    }
    //eslint-disable-next-line
  }, [])

  if (isError) {
    return <Error>Fehler beim Laden der Produkte.</Error>
  }

  if (isLoading || !data || settings.loading) {
    return <div><Spacer/><SpinnerContainer><Spinner/></SpinnerContainer></div>
  }

  if (data.data.length === 0) {
    return <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '50px' }}>
      Keine Produkte gefunden
    </div>
  }

  if (data && settings.data) {
    return (
      <Box>
        <Wrapper>
          <Spacer/>
          <SimpleGrid minChildWidth="250px" spacing={5} id={'int-grid'}>
            {data.data.map(product => <GridItem key={product.id} product={product}/>)}
          </SimpleGrid>
        </Wrapper>
      </Box>
    )
  }
};

RandomShopItems.defaultProps = {
  limit: 4,
}

export default RandomShopItems;
