import React                       from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Img                         from 'gatsby-image';
import styled                      from '@emotion/styled';
import { Button, Center }          from '@chakra-ui/react';

import { Link }      from '@interness/web-core/src/components/elements/Link/Link';
import { findMedia } from '@interness/web-core/src/components/_helpers';

const SDisplay = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

const SDisplayItem = styled.div`
  width: ${props => props.width};
  margin-bottom: 50px;
  position: relative;
  @media (max-width: 640px) {
    width: 100%;
  }

  > div {
    margin-bottom: 20px;
    position: relative;
    border: 5px solid ${props => props.theme.colorMode === 'dark' ? 'rgba(255,255,255,0.8)' : 'rgba(0,0,0,0.7)'} !important;
  }

  h4 {
    background-color: ${props => props.theme.colorMode === 'dark' ? 'rgba(255,255,255,0.8)' : 'rgba(0,0,0,0.7)'} !important;
    color: ${props => props.theme.colorMode === 'dark' ? '#333' : '#fff'} !important;
    text-transform: uppercase;
    padding: 10px 0;
    margin: auto;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    text-align: center;
  }

  h5 {
    font-size: 1.2rem;
    font-weight: 300;
    text-align: left;
    margin-top: 10px;
  }

  p {
    text-align: justify;
    font-size: 0.9rem;
  }

  a {
    position: absolute;
    bottom: -65px;
  }
`;

const FurtherLinksSection = () => {
  const data = useStaticQuery(query);
  const items = [
    {
      name: 'Materialkunde',
      link: '/materialkunde',
      image: findMedia(data.images.media, 'material-img-q-1')
    },
    {
      name: 'Fragen und Antworten',
      link: '/faq',
      image: findMedia(data.images.media, 'faq-img-q-1')
    }
  ]
  return (
    <div>
      <SDisplay>
        {items.map(item => (
          <SDisplayItem width={'45%'} key={item.name}>
            <div>
              <Img fluid={item.image.childImageSharp.fluid} alt={item.name}/>
              <h4>{item.name}</h4>
              <Center>
                <Button sx={{ textDecoration: 'none' }} colorScheme="brand" as={Link} to={item.link}>Mehr
                  erfahren</Button>
              </Center>
            </div>
          </SDisplayItem>
        ))}
      </SDisplay>
    </div>
  )
};

export default FurtherLinksSection;

const query = graphql`
    query {
        images: directusGlobalMediaCollection(name: {eq: "wedding_ring_studio_default"}) {
            name
            media {
                file: directus_files_id {
                    localFile {
                        name
                        childImageSharp {
                            fluid(maxWidth: 700, cropFocus: CENTER) {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                }
            }
        }
    }
`;
